import React from 'react';
import { Constrain, Breakpoint, Clickable, Divider } from '@moda/om';
import LogoHorizontalIcon from '@moda/icons/logo-horizontal-189-x-12';
import { useDesktopNavigator } from '../../../hooks/useNavigator';
import {
  SiteFooterBasementCheckoutDesktop,
  SiteFooterBasementCheckoutMobile
} from './SiteFooterBasementCheckout';

import './SiteFooterCheckout.scss';

export const SiteFooterCheckout: React.FC = () => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return (
    <Constrain className="SiteFooterCheckout">
      <div className="SiteFooterCheckout__logo">
        <Clickable to="/" aria-label="Go to homepage">
          <LogoHorizontalIcon />
        </Clickable>
      </div>

      {!isDesktopSiteNavV2Enabled && <Divider />}

      <Breakpoint gt="sm">
        <SiteFooterBasementCheckoutDesktop />
      </Breakpoint>

      <Breakpoint lt="sm">
        <SiteFooterBasementCheckoutMobile />
      </Breakpoint>
    </Constrain>
  );
};
