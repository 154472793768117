import React from 'react';
import { ControlLink } from '@moda/om';
import classNames from 'classnames';
import { hrefFor } from '../../../../routers';
import { useDesktopNavigator } from '../../../../hooks/useNavigator';

import './SiteFooterBasementCheckoutDesktop.scss';

export const SiteFooterBasementCheckoutDesktop: React.FC = () => {
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return (
    <section className="SiteFooterBasementCheckoutDesktop">
      <div
        className={classNames('SiteFooterInlineItem', {
          'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
        })}
      >
        <ControlLink to={`${hrefFor.AboutPage()}#contact`} underlined={false}>
          Contact Us
        </ControlLink>
      </div>

      <div
        className={classNames('SiteFooterInlineItem', {
          'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
        })}
      >
        <ControlLink to={hrefFor.TermsAndConditionsPage()} underlined={false}>
          Terms &amp; Conditions
        </ControlLink>
      </div>

      <div
        className={classNames('SiteFooterInlineItem', {
          'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
        })}
      >
        <ControlLink to={hrefFor.PrivacyPolicyPage()} underlined={false}>
          Privacy Policy
        </ControlLink>
      </div>

      <div
        className={classNames('SiteFooterInlineItem', {
          'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
        })}
      >
        © {new Date().getFullYear()} Moda Operandi, Inc.
      </div>

      <div
        className={classNames('SiteFooterInlineItem', {
          'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
        })}
      >
        All Rights Reserved.
      </div>
    </section>
  );
};
