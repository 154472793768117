import { Text, TextColor } from '@moda/om';
import React, { useCallback } from 'react';
import classNames from 'classnames';
import { DesktopNavigatorVerticalItemFragment } from '../../../generated/types';
import { tc } from '../../../lib/trackingContext';
import { Link } from '../../Link';
import { useDesktopNavigationV2 } from '../useDesktopNavigationV2';

import './DesktopNavigationV2MainContentCategory.scss';

type Props = {
  category: DesktopNavigatorVerticalItemFragment;
  selected: boolean;
  onSelect: () => void;
};

export const DesktopNavigationV2MainContentCategory: React.FC<Props> = ({
  category,
  selected: isSelected,
  onSelect
}) => {
  const { selectedVerticalData } = useDesktopNavigationV2();

  const handleClick = useCallback(
    ({ pageX: coordinateX, pageY: coordinateY }: React.MouseEvent) =>
      tc.track('Navigation Item Clicked', {
        addToTrackingEvent: {
          url: category.href,
          text: category.name,
          label: category.name,
          hierarchy: `${selectedVerticalData?.title}/${category.name}`,
          coordinateX,
          coordinateY
        }
      }),
    [category.href, category.name, selectedVerticalData?.title]
  );

  return (
    <Link
      className={classNames('DesktopNavigationV2MainContentCategory', {
        'DesktopNavigationV2MainContentCategory--selected': isSelected,
        'DesktopNavigationV2MainContentCategory--unselected': !isSelected
      })}
      data-testid="DesktopNavigationV2MainContentCategory"
      onMouseOver={onSelect}
      onClick={handleClick}
      to={category.href}
    >
      <Text
        treatment={category.style.bold ? 'bold1' : undefined}
        color={(category.style.color as TextColor) || 'ink'}
      >
        {category.name}
      </Text>
    </Link>
  );
};
