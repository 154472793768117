import React, { useCallback } from 'react';
import { gql } from '@apollo/client';
import { PromoBanner, useBreakpoint } from '@moda/om';
import md5 from 'md5';
import { isClientSide } from '@moda/portal-stanchions';
import classNames from 'classnames';
import { Link } from '../Link';
import {
  SitePromoBannerFragment,
  SitePromoBannerPumoModuleFragment,
  SitePromoBannerPumoModuleSlideFragment
} from '../../generated/types';
import { useEnableClubModaTreatment } from '../../hooks/useEnableClubModaTreatment';
import { useDesktopNavigator } from '../../hooks/useNavigator';
import { stripHTMLTags } from '../NormalizePumoStyles';
import { tc } from '../../lib/trackingContext';
import { Crossfader } from '../Crossfader';
import { SitePromoBannerCountrySelector } from './SitePromoBannerCountrySelector';
import { SitePromoBannerLink } from './SitePromoBannerLink';

import './SitePromoBanner.scss';

const SITE_PROMO_BANNER_PUMO_MODULE_SLIDE_FRAGMENT = gql`
  fragment SitePromoBannerPumoModuleSlideFragment on Slide {
    ctaUrl
    slideText {
      ctaText
      taglineText {
        body
      }
    }
  }
`;

const SITE_PROMO_BANNER_PUMO_MODULE_FRAGMENT = gql`
  fragment SitePromoBannerPumoModuleFragment on SinglePumoModule {
    platform
    slides {
      ...SitePromoBannerPumoModuleSlideFragment
    }
  }
  ${SITE_PROMO_BANNER_PUMO_MODULE_SLIDE_FRAGMENT}
`;

export const SITE_PROMO_BANNER_FRAGMENT = gql`
  fragment SitePromoBannerFragment on DisplayPage {
    targetBuckets {
      name
      modules {
        ...SitePromoBannerPumoModuleFragment
      }
    }
  }
  ${SITE_PROMO_BANNER_PUMO_MODULE_FRAGMENT}
`;

const SITE_PROMO_BANNER_FALLBACK_MESSAGE = 'Discover new collections at Moda Operandi';

const BANNER_DURATION_MS = 4000;
const BANNER_TRANSITION_MS = 1400;

interface Props {
  displayPage?: SitePromoBannerFragment | null;
}

export const SitePromoBanner: React.FC<Props> = ({ displayPage }) => {
  const isMobile = useBreakpoint({ lt: 'md' }).matches;

  const bannerModules = displayPage?.targetBuckets.find(({ name }) => name === 'banner')
    ?.modules as SitePromoBannerPumoModuleFragment[];

  const modulesByPlatform = (bannerModules || []).reduce(
    (obj, currModule) => {
      if (isMobile && currModule.platform.includes('mobile')) {
        return { ...obj, mobile: [...obj.mobile, currModule] };
      } else if (!isMobile && currModule.platform.includes('desktop')) {
        return { ...obj, desktop: [...obj.desktop, currModule] };
      } else {
        return obj;
      }
    },
    { mobile: [], desktop: [] } as Record<string, SitePromoBannerPumoModuleFragment[]>
  );

  const bannerSlides: SitePromoBannerPumoModuleSlideFragment[] | null = (
    modulesByPlatform[isMobile ? 'mobile' : 'desktop']?.[0] as SitePromoBannerPumoModuleFragment
  )?.slides;

  const trackHrefClick = useCallback(
    (taglineText: string, promoId: string, event: React.MouseEvent) => {
      tc.track('Promotion Clicked', {
        addToTrackingEvent: {
          position: 'promo_banner',
          name: taglineText,
          promotion_id: promoId,
          label: promoId,
          coordinateX: event?.pageX || 0,
          coordinateY: event?.pageY || 0
        }
      });
    },
    []
  );

  const findPromoText = (text: string | null | undefined) => {
    if (!isClientSide()) return text;
    if (text == null) return undefined;
    try {
      const element = document.createElement('div');
      element.innerHTML = text;
      return (element.textContent || '').trim();
    } catch {
      return text;
    }
  };

  const enableClubModaTreatment = useEnableClubModaTreatment();
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return (
    <PromoBanner
      role="banner"
      className={classNames('SitePromoBanner', {
        'SitePromoBanner--club': enableClubModaTreatment,
        'SitePromoBanner--nav-v2': isDesktopSiteNavV2Enabled
      })}
      data-test-id="site-promo-banner"
    >
      <div>
        <SitePromoBannerCountrySelector
          className={classNames('SitePromoBanner__country-selector', {
            'SitePromoBanner__country-selector--club': enableClubModaTreatment,
            'SitePromoBanner__cta--nav-v2': isDesktopSiteNavV2Enabled
          })}
        />
      </div>

      <div>
        {bannerSlides && bannerSlides.length > 0 ? (
          <Crossfader
            className="SitePromoBanner__cell"
            duration={BANNER_DURATION_MS}
            transition={BANNER_TRANSITION_MS}
            active={bannerSlides.length > 1}
          >
            {bannerSlides.map(slide => (
              <Link
                key={slide?.slideText?.taglineText?.body || SITE_PROMO_BANNER_FALLBACK_MESSAGE}
                className={classNames('SitePromoBanner__cta', {
                  'SitePromoBanner__cta--club': enableClubModaTreatment,
                  'SitePromoBanner__cta--nav-v2': isDesktopSiteNavV2Enabled
                })}
                to={slide?.ctaUrl || '/new'}
                onClick={event =>
                  trackHrefClick(
                    findPromoText(slide?.slideText?.taglineText?.body) || '',
                    slide?.slideText?.ctaText || md5(slide?.slideText?.taglineText?.body || ''),
                    event
                  )
                }
                title={stripHTMLTags(
                  slide?.slideText?.taglineText?.body || SITE_PROMO_BANNER_FALLBACK_MESSAGE
                )}
                dangerouslySetInnerHTML={{
                  __html: stripHTMLTags(
                    slide?.slideText?.taglineText?.body || SITE_PROMO_BANNER_FALLBACK_MESSAGE
                  )
                }}
              />
            ))}
          </Crossfader>
        ) : (
          <Link
            className="SitePromoBanner__cta"
            to="/new"
            title={SITE_PROMO_BANNER_FALLBACK_MESSAGE}
          >
            {SITE_PROMO_BANNER_FALLBACK_MESSAGE}
          </Link>
        )}
      </div>

      <div>
        <SitePromoBannerLink />
      </div>
    </PromoBanner>
  );
};
