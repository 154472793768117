import React, { useCallback } from 'react';
import { Clickable, Popover, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import AccountIcon from '@moda/icons/account-24';
import { hrefFor } from '../../routers';
import { Z_INDEXES } from '../../generated/zIndexes';
import { useUser } from '../../hooks/useUser';
import { useAuthModal } from '../../hooks/useAuthModal';
import { AccountLinks } from '../AccountLinks';
import { Link } from '../Link';
import { useEnableClubModaTreatment } from '../../hooks/useEnableClubModaTreatment';
import { useDesktopNavigator } from '../../hooks/useNavigator';

import './SitePromoBannerLink.scss';

const MAX_CHARACTERS = 10;

export const SitePromoBannerLinkSignedOut: React.FC = () => {
  const { openAuthModal } = useAuthModal();

  const handleClick = useCallback(() => {
    openAuthModal();
  }, [openAuthModal]);

  const enableClubModaTreatment = useEnableClubModaTreatment();
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return (
    <Clickable
      className={classNames('SitePromoBannerLink', {
        'SitePromoBannerLink--club': enableClubModaTreatment
      })}
      data-test-id="sign-in-button"
      onClick={handleClick}
    >
      {isDesktopSiteNavV2Enabled ? (
        <AccountIcon height="24px" width="24px" />
      ) : (
        <Text>Sign In</Text>
      )}
    </Clickable>
  );
};

export const SitePromoBannerLinkSignedIn: React.FC<{ firstName: string | null }> = ({
  firstName
}) => {
  const enableClubModaTreatment = useEnableClubModaTreatment();
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  const truncateName = (name: string | null) => {
    if (!name) return 'Welcome!';
    return name.length > MAX_CHARACTERS ? `${name.substring(0, MAX_CHARACTERS)}...` : name;
  };

  const displayName = truncateName(firstName);

  return isDesktopSiteNavV2Enabled ? (
    <Popover
      className="SitePromoBannerLink__popover"
      zIndex={Z_INDEXES['site-promo-banner-popover']}
      content={<AccountLinks />}
      anchor="topRight"
    >
      <Stack space={1} direction="horizontal" alignItems="center">
        <AccountIcon height="24px" width="24px" />
        <Link
          className={classNames('SitePromoBannerLink', {
            'SitePromoBannerLink--club': enableClubModaTreatment,
            'SitePromoBannerLink--nav-v2': isDesktopSiteNavV2Enabled
          })}
          to="/account"
        >
          {firstName ? (
            <Stack space={0}>
              <Text treatment="body2" className="SitePromoBannerLink__greetings-top">
                Hi,
              </Text>
              <Text treatment="body2" className="SitePromoBannerLink__greetings-bottom">
                {displayName}
              </Text>
            </Stack>
          ) : (
            'Welcome!'
          )}
        </Link>
      </Stack>
    </Popover>
  ) : (
    <Popover
      className="SitePromoBannerLink__popover"
      zIndex={Z_INDEXES['site-promo-banner-popover']}
      content={<AccountLinks />}
      anchor="topRight"
    >
      <Link
        className={classNames('SitePromoBannerLink', {
          'SitePromoBannerLink--club': enableClubModaTreatment
        })}
        to="/account"
      >
        {firstName ? `Hi, ${firstName}` : 'Welcome!'}
      </Link>
    </Popover>
  );
};

export const SitePromoBannerLink: React.FC = () => {
  const { user } = useUser();
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return isDesktopSiteNavV2Enabled ? (
    <Stack space={1} direction="horizontal" justifyContent="flex-end">
      <Clickable
        className={classNames('SitePromoBannerLink', 'SitePromoBannerLink__left', {
          'SitePromoBannerLink--nav-v2': isDesktopSiteNavV2Enabled
        })}
        to={hrefFor.ModaPrivate()}
      >
        Need a Stylist?
      </Clickable>
    </Stack>
  ) : user.isLoggedIn ? (
    <SitePromoBannerLinkSignedIn firstName={user.firstName} />
  ) : (
    <SitePromoBannerLinkSignedOut />
  );
};
