import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Loading, Modal } from '@moda/om';
import { MobileNavV2Menu } from '../MobileNavV2Menu';
import { useMobileNavigator } from '../../../hooks/useNavigator';

import './MobileNavV2Overlay.scss';

enum Mode {
  Resting,
  Mounted
}

interface Props {
  onClose(): void;
  navRef: HTMLDivElement | null;
}

export const MobileNavV2Overlay: React.FC<Props> = ({ onClose, navRef }) => {
  const [mode, setMode] = useState(Mode.Resting);
  const { loading } = useMobileNavigator();

  const top = useMemo(() => {
    const siteNav = document.querySelector('.SiteNav');

    if (siteNav) {
      const { top, height } = siteNav.getBoundingClientRect();

      return top + height;
    }
  }, []);

  useEffect(() => setMode(Mode.Mounted), []);

  const handlePreventClose = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => event.stopPropagation(),
    []
  );

  return (
    <Modal
      className={classNames('MobileNavV2Overlay', {
        'MobileNavV2Overlay--active': mode === Mode.Mounted
      })}
      tabIndex={0}
      role="button"
      onClose={onClose}
      style={{ top }}
      shards={navRef ? [navRef] : undefined}
    >
      {loading && <Loading />}

      <MobileNavV2Menu onClick={handlePreventClose} onClose={onClose} />
    </Modal>
  );
};
