import React, { useMemo } from 'react';
import {
  DesktopNavigatorVerticalItemFragment,
  EditorialNavigatorUnitFragment,
  ListNavigatorUnitFragment
} from '../../../generated/types';
import { DesktopNavigationV2ListColumnV2 } from '../DesktopNavigationV2ListColumnV2';
import { DesktopNavigationV2EditorialColumn } from '../DesktopNavigationV2EditorialColumn';

import './DesktopNavigationV2Pulldown.scss';

type Props = {
  content: DesktopNavigatorVerticalItemFragment;
};

export const DesktopNavigationV2Pulldown: React.FC<Props> = ({ content }) => {
  const [listNavigatorUnits, editorialNavigatorUnits] = useMemo(() => {
    const listUnits: ListNavigatorUnitFragment[] = [];

    const editorialUnits: EditorialNavigatorUnitFragment[] = [];

    content.units.forEach(unit => {
      if (unit.__typename === 'ListNavigatorUnit') {
        listUnits.push(unit);
        return;
      }

      if (unit.__typename === 'EditorialNavigatorUnit') {
        editorialUnits.push(unit);
        return;
      }
    });

    return [listUnits, editorialUnits] as const;
  }, [content.units]);

  return (
    <div className="DesktopNavigationV2Pulldown">
      {listNavigatorUnits.map((unit, index) => (
        <DesktopNavigationV2ListColumnV2
          className="DesktopNavigationV2Pulldown__column"
          key={index}
          parentLabel={content.name}
          content={unit}
        />
      ))}

      {editorialNavigatorUnits.map((unit, index) => (
        <DesktopNavigationV2EditorialColumn
          className="DesktopNavigationV2Pulldown__column"
          content={unit}
          key={index}
        />
      ))}
    </div>
  );
};
