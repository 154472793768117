import { useLocation } from 'react-router-dom';
import { useQueryParams } from '@moda/portal-stanchions';
import { useCurrentRouteParams } from '../../hooks/useCurrentRouteParams';
import { useDesktopNavigator } from '../../hooks/useNavigator';

export const useDesktopNavigationV2 = () => {
  const { pathname } = useLocation();
  const { queryParams } = useQueryParams();
  const params = useCurrentRouteParams<{ vertical?: string }>();
  const { data: verticals, loading, error } = useDesktopNavigator();

  const verticalSlug = params?.vertical ?? queryParams.vertical;

  const selectedVerticalData =
    verticals?.find(
      vertical => pathname === vertical.href || vertical.href.replace('/', '') === verticalSlug
    ) ??
    verticals?.find(vertical => vertical.href === '/') ??
    verticals?.[0];

  return { data: verticals, loading, error, selectedVerticalData };
};
