import React from 'react';
import { Clickable, Stack, Text } from '@moda/om';
import classNames from 'classnames';
import SocialPhoneIcon from '@moda/icons/social-phone-16';
import SocialFacebookIcon from '@moda/icons/social-facebook-16';
import SocialInstagramIcon from '@moda/icons/social-instagram-16';
import SocialPinterestIcon from '@moda/icons/social-pinterest-16';
import SocialXIcon from '@moda/icons/social-x-20';
import { useCountry } from '../../CountrySelector';
import { useDesktopNavigator } from '../../../hooks/useNavigator';

import './SiteFooterBasement.scss';

export const SiteFooterBasement: React.FC = () => {
  const { country } = useCountry();
  const showPrivacyChoicesLink = country.alpha2Code === 'US';
  const { isDesktopSiteNavV2Enabled } = useDesktopNavigator();

  return (
    <Stack
      space={1}
      direction="horizontal"
      alignItems="center"
      justifyContent="space-between"
      className={classNames('SiteFooterBasement', {
        'SiteFooterBasement--refresh': isDesktopSiteNavV2Enabled
      })}
    >
      <Stack
        space={1}
        direction="horizontal"
        className={classNames('SiteFooterBasement__left', {
          'SiteFooterBasement__left--refresh': isDesktopSiteNavV2Enabled
        })}
      >
        <a
          className={classNames('SiteFooterInlineItem SiteFooterInlineItem__app', {
            'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
          })}
          data-test-id="download-the-moda-app"
          href="https://itunes.apple.com/us/app/moda-operandi/id781702094"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SocialPhoneIcon
            className={classNames('SiteFooterDesktop__icon', {
              'SiteFooterDesktop__icon--refresh': isDesktopSiteNavV2Enabled
            })}
          />
          Download the Moda App
        </a>

        <Stack
          space={2}
          direction="horizontal"
          className={classNames('SiteFooterInlineItem', {
            'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
          })}
        >
          <a
            href="https://www.instagram.com/modaoperandi/"
            data-test-id="moda-on-ig"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Instagram"
            className={classNames('SiteFooterDesktop__icon', {
              'SiteFooterDesktop__icon--refresh': isDesktopSiteNavV2Enabled
            })}
          >
            <SocialInstagramIcon />
          </a>
          <a
            href="https://www.facebook.com/ModaOperandi"
            target="_blank"
            data-test-id="moda-on-fb"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Facebook"
            className={classNames('SiteFooterDesktop__icon', {
              'SiteFooterDesktop__icon--refresh': isDesktopSiteNavV2Enabled
            })}
          >
            <SocialFacebookIcon />
          </a>
          <a
            href="https://twitter.com/modaoperandi"
            target="_blank"
            data-test-id="moda-on-twitter"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Twitter"
            className={classNames('SiteFooterDesktop__icon', {
              'SiteFooterDesktop__icon--refresh': isDesktopSiteNavV2Enabled
            })}
          >
            <SocialXIcon
              className={classNames('SiteFooterDesktop__icon', {
                'SiteFooterDesktop__icon--refresh': isDesktopSiteNavV2Enabled
              })}
            />
          </a>
          <a
            href="https://www.pinterest.com/modaoperandi"
            target="_blank"
            data-test-id="moda-on-pinterest"
            rel="noopener noreferrer"
            aria-label="Moda Operandi on Pinterest"
            className={classNames('SiteFooterDesktop__icon', {
              'SiteFooterDesktop__icon--refresh': isDesktopSiteNavV2Enabled
            })}
          >
            <SocialPinterestIcon />
          </a>
        </Stack>

        <a
          className={classNames('SiteFooterInlineItem', {
            'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
          })}
          data-test-id="terms-and-conditions"
          href="/terms"
        >
          Terms &amp; Conditions
        </a>

        <a
          className={classNames('SiteFooterInlineItem', {
            'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
          })}
          data-test-id="privacy-policy"
          href="/privacy"
        >
          Privacy Policy
        </a>

        {showPrivacyChoicesLink && (
          <Clickable
            className={classNames('SiteFooterInlineItem', {
              'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
            })}
            href="#"
            onClick={() => window.evidon.notice.showOptions()}
          >
            <Text treatment="body2" color={!isDesktopSiteNavV2Enabled ? 'ink' : 'persian-blue'}>
              Your Privacy Choices
            </Text>
          </Clickable>
        )}

        <a
          className={classNames('SiteFooterInlineItem SiteFooterInlineItem__legal', {
            'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
          })}
          data-test-id="terms-and-conditions"
          href="/legal"
        >
          Legal
        </a>
      </Stack>
      <Stack space={2} direction="vertical">
        <img
          className="SiteFooterBasement__shapes"
          src="/dist/public/moda_logo.svg"
          alt="Moda shapes"
        />
        <div
          data-test-id="moda-copyright"
          className={classNames('SiteFooterInlineItem SiteFooterInlineItem__copyright', {
            'SiteFooterInlineItem--refresh': isDesktopSiteNavV2Enabled
          })}
        >
          © {new Date().getFullYear()} Moda Operandi, Inc. All Rights Reserved.
        </div>
      </Stack>
    </Stack>
  );
};
